// import { Helmet } from "react-helmet";
import React from "react";

const PrivacyPage = () => {
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Privacy Policy
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Please read our Privacy Policy before using our site.
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-1 lg:gap-6">
              <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                <div className="text-2xl">
                  Privacy Policy for SideHustleTool.com - owned and operated by
                  DGF Digital Inc.
                </div>
                <p>
                  At this site, accessible from sidehustletool.com, one of our
                  main priorities is the privacy of our visitors. This Privacy
                  Policy document contains types of information that is
                  collected and recorded by us and how we use it. If you have
                  additional questions or require more information about our
                  Privacy Policy, do not hesitate to{" "}
                  <a href="../contact">contact us.</a>
                </p>
                <div className="text-2xl pt-2">1. Introduction</div>
                <div>
                  <p>Welcome to SideHustleTool.</p>
                </div>
                <div className="pt-3">
                  <p>
                    DGF DIGITAL INC. ("company",“us”, “we”, or “our”) owns and
                    operates https://sidehustletool.com and SideHustleTool
                    mobile application (hereinafter referred to as “Service”).
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Our Privacy Policy governs your visit to
                    https://sidehustletool.com and SideHustleTool mobile
                    application, and explains how we collect, safeguard and
                    disclose information that results from your use of our
                    Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We use your data to provide and improve Service. By using
                    Service, you agree to the collection and use of information
                    in accordance with this policy. Unless otherwise defined in
                    this Privacy Policy, the terms used in this Privacy Policy
                    have the same meanings as in our Terms and Conditions.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Our Terms and Conditions (“Terms”) govern all use of our
                    Service and together with the Privacy Policy constitutes
                    your agreement with us (“agreement”).
                  </p>
                </div>

                <div className="text-2xl pt-2">2. Definitionss</div>
                <div>
                  <p>
                    SERVICE means the https://sidehustletool.com website and
                    subwebs and SideHustleTool mobile application owned and
                    operated by DGF DIGITAL INC..
                  </p>
                  <div className="pt-3">
                    <p>
                      PERSONAL DATA means data about a living individual who can
                      be identified from those data (or from those and other
                      information either in our possession or likely to come
                      into our possession).
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      USAGE DATA is data collected automatically either
                      generated by the use of Service or from Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      COOKIES are small files stored on your device (computer or
                      mobile device).
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      DATA CONTROLLER means a natural or legal person who
                      (either alone or jointly or in common with other persons)
                      determines the purposes for which and the manner in which
                      any personal data are, or are to be, processed. For the
                      purpose of this Privacy Policy, we are a Data Controller
                      of your data.
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      DATA PROCESSORS (OR SERVICE PROVIDERS) means any natural
                      or legal person who processes the data on behalf of the
                      Data Controller. We may use the services of various
                      Service Providers in order to process your data more
                      effectively.
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      DATA SUBJECT is any living individual who is the subject
                      of Personal Data.
                    </p>
                  </div>
                  <div className="pt-3">
                    <p>
                      THE USER is the individual using our Service. The User
                      corresponds to the Data Subject, who is the subject of
                      Personal Data.
                    </p>
                  </div>
                </div>

                <div className="text-2xl pt-2">
                  3. Information Collection and Use
                </div>
                <div>
                  <p>
                    We collect several different types of information for
                    various purposes to provide and improve our Service to you.
                  </p>
                </div>
                <div className="text-2xl pt-2">4. Types of Data Collected</div>
                <div>
                  <p>
                    Personal Data While using our Service, we may ask you to
                    provide us with certain personally identifiable information
                    that can be used to contact or identify you (“Personal
                    Data”). Personally identifiable information may include, but
                    is not limited to:
                  </p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>(a) Email address</li>
                    <li>(b) First name and last name</li>
                    <li>(c) Cookies and Usage Data</li>
                    <li>(d) I.P. Address</li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>
                    We may use your Personal Data to contact you with
                    newsletters, marketing or promotional materials and other
                    information that may be of interest to you. You may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by emailing at
                    info@sidehustletool.com.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Usage Data We may also collect information that your browser
                    sends whenever you visit our Service or when you access
                    Service by or through a mobile device (“Usage Data”).
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    This Usage Data may include information such as your
                    computer's Internet Protocol address (e.g. IP address),
                    browser type, browser version, the pages of our Service that
                    you visit, the time and date of your visit, the time spent
                    on those pages, unique device identifiers and other
                    diagnostic data.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    When you access Service with a mobile device, this Usage
                    Data may include information such as the type of mobile
                    device you use, your mobile device unique ID, the IP address
                    of your mobile device, your mobile operating system, the
                    type of mobile Internet browser you use, unique device
                    identifiers and other diagnostic data.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Tracking Cookies Data We use cookies and similar tracking
                    technologies to track the activity on our Service and we
                    hold certain information.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Cookies are files with a small amount of data which may
                    include an anonymous unique identifier. Cookies are sent to
                    your browser from a website and stored on your device. Other
                    tracking technologies are also used such as beacons, tags
                    and scripts to collect and track information and to improve
                    and analyze our Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can instruct your browser to refuse all cookies or to
                    indicate when a cookie is being sent. However, if you do not
                    accept cookies, you may not be able to use some portions of
                    our Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>Examples of Cookies we use:</p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) Session Cookies: We use Session Cookies to operate our
                      Service.
                    </li>
                    <li>
                      (b) Preference Cookies: We use Preference Cookies to
                      remember your preferences and various settings.
                    </li>
                    <li>
                      (c) Security Cookies: We use Security Cookies for security
                      purposes.
                    </li>
                    <li>
                      (d) Advertising Cookies: Advertising Cookies are used to
                      serve you with advertisements that may be relevant to you
                      and your interests.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                <div className="text-2xl">5. Use of Data</div>
                <p>
                  DGF DIGITAL INC. uses the collected data for various purposes:
                </p>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>(a) to provide and maintain our Service;</li>
                    <li>(b) to notify you about changes to our Service;</li>
                    <li>
                      (c) to allow you to participate in interactive features of
                      our Service when you choose to do so;
                    </li>
                    <li>(d) to provide customer support;</li>
                    <li>
                      (e) to gather analysis or valuable information so that we
                      can improve our Service;
                    </li>
                    <li>(f) to monitor the usage of our Service;</li>
                    <li>
                      (g) to detect, prevent and address technical issues;
                    </li>
                    <li>
                      (h) to fulfill any other purpose for which you provide it;
                    </li>
                    <li>
                      (i) to carry out our obligations and enforce our rights
                      arising from any contracts entered into between you and
                      us, including for billing and collection;
                    </li>
                    <li>
                      (j) to provide you with notices about your account and/or
                      subscription, including expiration and renewal notices,
                      email-instructions, etc.;
                    </li>
                    <li>
                      (k) to provide you with news, special offers and general
                      information about other goods, services and events which
                      we offer that are similar to those that you have already
                      purchased or enquired about unless you have opted not to
                      receive such information;
                    </li>
                    <li>
                      (l) in any other way we may describe when you provide the
                      information;
                    </li>
                    <li>(m) for any other purpose with your consent.</li>
                  </ul>
                </div>

                <div className="text-2xl pt-2">6. Retention of Data</div>
                <p>
                  We will retain your Personal Data only for as long as is
                  necessary for the purposes set out in this Privacy Policy. We
                  will retain and use your Personal Data to the extent necessary
                  to comply with our legal obligations (for example, if we are
                  required to retain your data to comply with applicable laws),
                  resolve disputes, and enforce our legal agreements and
                  policies.
                </p>
                <div className="pt-3">
                  <p>
                    We will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter
                    period, except when this data is used to strengthen the
                    security or to improve the functionality of our Service, or
                    we are legally obligated to retain this data for longer time
                    periods.
                  </p>
                </div>
                <div className="text-2xl pt-2">7. Transfer of Data</div>
                <p>
                  Your information, including Personal Data, may be transferred
                  to – and maintained on – computers located outside of your
                  state, province, country or other governmental jurisdiction
                  where the data protection laws may differ from those of your
                  jurisdiction.
                </p>
                <div className="pt-3">
                  <p>
                    If you are located outside United States and choose to
                    provide information to us, please note that we transfer the
                    data, including Personal Data, to United States and process
                    it there.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Your consent to this Privacy Policy followed by your
                    submission of such information represents your agreement to
                    that transfer.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    DGF DIGITAL INC. will take all the steps reasonably
                    necessary to ensure that your data is treated securely and
                    in accordance with this Privacy Policy and no transfer of
                    your Personal Data will take place to an organisation or a
                    country unless there are adequate controls in place
                    including the security of your data and other personal
                    information.
                  </p>
                </div>

                <div className="text-2xl pt-2">8. Disclosure of Data</div>
                <p>
                  We may disclose personal information that we collect, or you
                  provide:
                </p>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) Disclosure for Law Enforcement.
                      <div className="pt-3">
                        <p>
                          Under certain circumstances, we may be required to
                          disclose your Personal Data if required to do so by
                          law or in response to valid requests by public
                          authorities.
                        </p>
                      </div>
                    </li>
                    <li>
                      (b) Business Transaction.
                      <div className="pt-3">
                        <p>
                          If we or our subsidiaries are involved in a merger,
                          acquisition or asset sale, your Personal Data may be
                          transferred.
                        </p>
                      </div>
                    </li>
                    <li>
                      (c) Other cases. We may disclose your information also:
                      <div className="pt-3 pl-6">
                        <ul>
                          <li>(i) to our subsidiaries and affiliates;</li>
                          <li>
                            (ii) to fulfill the purpose for which you provide
                            it;
                          </li>
                          <li>
                            (iii) for any other purpose disclosed by us when you
                            provide the information;
                          </li>
                          <li>
                            (iv) if we believe disclosure is necessary or
                            appropriate to protect the rights, property, or
                            safety of the Company, our customers, or others.
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="text-2xl pt-2">9. Security of Data</div>
                <p>
                  The security of your data is important to us but remember that
                  no method of transmission over the Internet or method of
                  electronic storage is 100% secure. While we strive to use
                  commercially acceptable means to protect your Personal Data,
                  we cannot guarantee its absolute security.
                </p>
                <div className="text-2xl pt-2">
                  10. Your Data Protection Rights Under General Data Protection
                  Regulation (GDPR)
                </div>
                <p>
                  If you are a resident of the European Union (EU) and European
                  Economic Area (EEA), you have certain data protection rights,
                  covered by GDPR. – See more at
                  https://eur-lex.europa.eu/eli/reg/2016/679/oj{" "}
                </p>
                <div className="pt-3">
                  <p>
                    We aim to take reasonable steps to allow you to correct,
                    amend, delete, or limit the use of your Personal Data.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    If you wish to be informed what Personal Data we hold about
                    you and if you want it to be removed from our systems,
                    please email us at info@sidehustletool.com.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    In certain circumstances, you have the following data
                    protection rights:
                  </p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) the right to access, update or to delete the
                      information we have on you;
                    </li>
                    <li>
                      (b) the right of rectification. You have the right to have
                      your information rectified if that information is
                      inaccurate or incomplete;
                    </li>
                    <li>
                      (c) the right to object. You have the right to object to
                      our processing of your Personal Data;
                    </li>
                    <li>
                      (d) the right of restriction. You have the right to
                      request that we restrict the processing of your personal
                      information;
                    </li>
                    <li>
                      (e) the right to data portability. You have the right to
                      be provided with a copy of your Personal Data in a
                      structured, machine-readable and commonly used format;
                    </li>
                    <li>
                      (f) the right to withdraw consent. You also have the right
                      to withdraw your consent at any time where we rely on your
                      consent to process your personal information;
                    </li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>
                    Please note that we may ask you to verify your identity
                    before responding to such requests. Please note, we may not
                    able to provide Service without some necessary data.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You have the right to complain to a Data Protection
                    Authority about our collection and use of your Personal
                    Data. For more information, please contact your local data
                    protection authority in the European Economic Area (EEA).
                  </p>
                </div>

                <div className="text-2xl pt-2">
                  11. Your Data Protection Rights under the California Privacy
                  Protection Act (CalOPPA)
                </div>
                <p>
                  CalOPPA is the first state law in the nation to require
                  commercial websites and online services to post a privacy
                  policy. The law’s reach stretches well beyond California to
                  require a person or company in the United States (and
                  conceivable the world) that operates websites collecting
                  personally identifiable information from California consumers
                  to post a conspicuous privacy policy on its website stating
                  exactly the information being collected and those individuals
                  with whom it is being shared, and to comply with this policy.
                  – See more at:
                  https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/{" "}
                </p>
                <div className="pt-3">
                  <p>According to CalOPPA we agree to the following:</p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>(a) users can visit our site anonymously;</li>
                    <li>
                      (b) our Privacy Policy link includes the word “Privacy”,
                      and can easily be found on the page specified above on the
                      home page of our website;
                    </li>
                    <li>
                      (c) users will be notified of any privacy policy changes
                      on our Privacy Policy Page;
                    </li>
                    <li>
                      (d) users are able to change their personal information by
                      emailing us at info@sidehustletool.com.
                    </li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>Our Policy on “Do Not Track” Signals:</p>
                </div>
                <div className="pt-3">
                  <p>
                    We honor Do Not Track signals and do not track, plant
                    cookies, or use advertising when a Do Not Track browser
                    mechanism is in place. Do Not Track is a preference you can
                    set in your web browser to inform websites that you do not
                    want to be tracked.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can enable or disable Do Not Track by visiting the
                    Preferences or Settings page of your web browser.
                  </p>
                </div>

                <div className="text-2xl pt-2">
                  12. Your Data Protection Rights under the California Consumer
                  Privacy Act (CCPA)
                </div>
                <p>
                  If you are a California resident, you are entitled to learn
                  what data we collect about you, ask to delete your data and
                  not to sell (share) it. To exercise your data protection
                  rights, you can make certain requests and ask us:
                </p>
                <div className="pt-3">
                  <p>
                    (a) What personal information we have about you. If you make
                    this request, we will return to you:
                  </p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (i) The categories of personal information we have
                      collected about you.
                    </li>
                    <li>
                      (ii) The categories of sources from which we collect your
                      personal information.
                    </li>
                    <li>
                      (iii) The business or commercial purpose for collecting or
                      selling your personal information.
                    </li>
                    <li>
                      (iv) The categories of third parties with whom we share
                      personal information.
                    </li>
                    <li>
                      (v) The specific pieces of personal information we have
                      collected about you.
                    </li>
                    <li>
                      (vi) A list of categories of personal information that we
                      have sold, along with the category of any other company we
                      sold it to. If we have not sold your personal information,
                      we will inform you of that fact.
                    </li>
                    <li>
                      (vii) A list of categories of personal information that we
                      have disclosed for a business purpose, along with the
                      category of any other company we shared it with.
                    </li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>
                    Please note, you are entitled to ask us to provide you with
                    this information up to two times in a rolling twelve-month
                    period. When you make this request, the information provided
                    may be limited to the personal information we collected
                    about you in the previous 12 months.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    (b) To delete your personal information. If you make this
                    request, we will delete the personal information we hold
                    about you as of the date of your request from our records
                    and direct any service providers to do the same. In some
                    cases, deletion may be accomplished through
                    de-identification of the information. If you choose to
                    delete your personal information, you may not be able to use
                    certain functions that require your personal information to
                    operate.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    (c) To stop selling your personal information. We don't sell
                    or rent your personal information to any third parties for
                    any purpose. You are the only owner of your Personal Data
                    and can request disclosure or deletion at any time.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Please note, if you ask us to delete or stop selling your
                    data, it may impact your experience with us, and you may not
                    be able to participate in certain programs or membership
                    services which require the usage of your personal
                    information to function. But in no circumstances, we will
                    discriminate against you for exercising your rights.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    To exercise your California data protection rights described
                    above, please send your request(s) by one of the following
                    means:
                  </p>
                </div>
                <div className="pt-3">
                  <p>By email: info@sidehustletool.com</p>
                </div>
                <div className="pt-3">
                  <p>
                    Your data protection rights, described above, are covered by
                    the CCPA, short for the California Consumer Privacy Act. To
                    find out more, visit the official California Legislative
                    Information website. The CCPA took effect on 01/01/2020.
                  </p>
                </div>

                <div className="text-2xl pt-2">13. Service Providers</div>
                <p>
                  We may employ third party companies and individuals to
                  facilitate our Service (“Service Providers”), provide Service
                  on our behalf, perform Service-related services or assist us
                  in analysing how our Service is used.
                </p>
                <div className="pt-3">
                  <p>
                    These third parties have access to your Personal Data only
                    to perform these tasks on our behalf and are obligated not
                    to disclose or use it for any other purpose.
                  </p>
                </div>

                <div className="text-2xl pt-2">14. Analytics</div>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>
                <div className="pt-3">
                  <p>
                    Google Analytics Google Analytics is a web analytics service
                    offered by Google that tracks and reports website traffic.
                    Google uses the data collected to track and monitor the use
                    of our Service. This data is shared with other Google
                    services. Google may use the collected data to contextualise
                    and personalise the ads of its own advertising network.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    https://policies.google.com/privacy?hl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We also encourage you to review the Google's policy for
                    safeguarding your data:
                    https://support.google.com/analytics/answer/6004245.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Firebase Firebase is analytics service provided by Google
                    Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You may opt-out of certain Firebase features through your
                    mobile device settings, such as your device advertising
                    settings or by following the instructions provided by Google
                    in their Privacy Policy:
                    https://policies.google.com/privacy?hl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on what type of information Firebase
                    collects, please visit the Google Privacy Terms web page:
                    https://policies.google.com/privacy?hl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>CI/CD tools</p>
                </div>
                <div className="pt-3">
                  <p>
                    We may use third-party Service Providers to automate the
                    development process of our Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>GitHub</p>
                </div>
                <div className="pt-3">
                  <p>GitHub is provided by GitHub, Inc.</p>
                </div>
                <div className="pt-3">
                  <p>
                    GitHub is a development platform to host and review code,
                    manage projects, and build software.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on what data GitHub collects for what
                    purpose and how the protection of the data is ensured,
                    please visit GitHub Privacy Policy page:
                    https://help.github.com/en/articles/github-privacy-statement.
                  </p>
                </div>

                <div className="text-2xl pt-2">15. Advertising</div>
                <p>
                  We may use third-party Service Providers to show
                  advertisements to you to help support and maintain our
                  Service.
                </p>
                <div className="pt-3">
                  <p>
                    Google AdSense DoubleClick Cookie Google, as a third party
                    vendor, uses cookies to serve ads on our Service. Google's
                    use of the DoubleClick cookie enables it and its partners to
                    serve ads to our users based on their visit to our Service
                    or other websites on the Internet.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You may opt out of the use of the DoubleClick Cookie for
                    interest-based advertising by visiting the Google Ads
                    Settings web page: http://www.google.com/ads/preferences/
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Bing Ads Bing Ads is an advertising service provided by
                    Microsoft Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can opt-out from Bing Ads by following the instructions
                    on Bing Ads Opt-out page:
                    https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information about Bing Ads, please visit their
                    Privacy Policy:
                    https://privacy.microsoft.com/en-us/PrivacyStatement
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    AdMob by Google AdMob by Google is provided by Google Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can opt-out from the AdMob by Google service by
                    following the instructions described by Google:
                    https://support.google.com/ads/answer/2662922?hl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on how Google uses the collected
                    information, please visit the “How Google uses data when you
                    use our partners' sites or app” page:
                    http://www.google.com/policies/privacy/partners/ or visit
                    the Privacy Policy of Google:
                    http://www.google.com/policies/privacy/
                  </p>
                </div>

                <div className="text-2xl pt-2">16. Behavioral Remarketing</div>
                <p>
                  DGF DIGITAL INC. uses remarketing services to advertise on
                  third party websites to you after you visited our Service. We
                  and our third-party vendors use cookies to inform, optimise
                  and serve ads based on your past visits to our Service.
                </p>
                <div className="pt-3">
                  <p>
                    Google Ads (AdWords) Google Ads (AdWords) remarketing
                    service is provided by Google Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can opt-out of Google Analytics for Display Advertising
                    and customize the Google Display Network ads by visiting the
                    Google Ads Settings page: http://www.google.com/settings/ads
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Google also recommends installing the Google Analytics
                    Opt-out Browser Add-on –
                    https://tools.google.com/dlpage/gaoptout – for your web
                    browser. Google Analytics Opt-out Browser Add-on provides
                    visitors with the ability to prevent their data from being
                    collected and used by Google Analytics.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    https://policies.google.com/privacy?hl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Twitter Twitter remarketing service is provided by Twitter
                    Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can opt-out from Twitter's interest-based ads by
                    following their instructions:
                    https://support.twitter.com/articles/20170405
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can learn more about the privacy practices and policies
                    of Twitter by visiting their Privacy Policy page:
                    https://twitter.com/privacy
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Facebook Facebook remarketing service is provided by
                    Facebook Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You can learn more about interest-based advertising from
                    Facebook by visiting this page:
                    https://www.facebook.com/help/164968693837950
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    To opt-out from Facebook's interest-based ads, follow these
                    instructions from Facebook:
                    https://www.facebook.com/help/568137493302217
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Facebook adheres to the Self-Regulatory Principles for
                    Online Behavioural Advertising established by the Digital
                    Advertising Alliance. You can also opt-out from Facebook and
                    other participating companies through the Digital
                    Advertising Alliance in the USA
                    http://www.aboutads.info/choices/, the Digital Advertising
                    Alliance of Canada in Canada http://youradchoices.ca/ or the
                    European Interactive Digital Advertising Alliance in Europe
                    http://www.youronlinechoices.eu/, or opt-out using your
                    mobile device settings.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on the privacy practices of Facebook,
                    please visit Facebook's Data Policy:
                    https://www.facebook.com/privacy/explanation
                  </p>
                </div>

                <div className="text-2xl pt-2">17. Payments</div>
                <p>
                  We may provide paid products and/or services within Service.
                  In that case, we use third-party services for payment
                  processing (e.g. payment processors).
                </p>
                <div className="pt-3">
                  <p>
                    We will not store or collect your payment card details. That
                    information is provided directly to our third-party payment
                    processors whose use of your personal information is
                    governed by their Privacy Policy. These payment processors
                    adhere to the standards set by PCI-DSS as managed by the PCI
                    Security Standards Council, which is a joint effort of
                    brands like Visa, Mastercard, American Express and Discover.
                    PCI-DSS requirements help ensure the secure handling of
                    payment information.
                  </p>
                </div>
                <div className="pt-3">
                  <p>The payment processors we work with are:</p>
                </div>
                <div className="pt-3">
                  <p>
                    PayPal or Braintree: Their Privacy Policy can be viewed at
                    https://www.paypal.com/webapps/mpp/ua/privacy-full
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Apple Store In-App Payments: Their Privacy Policy can be
                    viewed at: https://www.apple.com/legal/privacy/en-ww/ /
                    https://support.apple.com/en-us/HT203027
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Google Play In-App Payments: Their Privacy Policy can be
                    viewed at: https://policies.google.com/privacy?hl=en&gl=us /
                    https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=privacynotice&ldl=en
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Stripe: Their Privacy Policy can be viewed at:
                    https://stripe.com/us/privacy
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Square: Their Privacy Policy can be viewed at:
                    https://squareup.com/us/en/legal/general/privacy
                  </p>
                </div>

                <div className="text-2xl pt-2">18. Links to Other Sites</div>
                <p>
                  Our Service may contain links to other sites that are not
                  operated by us. If you click a third party link, you will be
                  directed to that third party's site. We strongly advise you to
                  review the Privacy Policy of every site you visit.
                </p>
                <div className="pt-3">
                  <p>
                    We have no control over and assume no responsibility for the
                    content, privacy policies or practices of any third party
                    sites or services.
                  </p>
                </div>

                <div className="text-2xl pt-2">19. Children's Privacy</div>
                <p>
                  Our Services are not intended for use by children under the
                  age of 18 (“Child” or “Children”).{" "}
                </p>
                <div className="pt-3">
                  <p>
                    We do not knowingly collect personally identifiable
                    information from Children under 18. If you become aware that
                    a Child has provided us with Personal Data, please contact
                    us. If we become aware that we have collected Personal Data
                    from Children without verification of parental consent, we
                    take steps to remove that information from our servers.
                  </p>
                </div>

                <div className="text-2xl pt-2">
                  20. Changes to This Privacy Policy
                </div>
                <p>
                  We may update our Privacy Policy from time to time. We will
                  notify you of any changes by posting the new Privacy Policy on
                  this page.
                </p>
                <div className="pt-3">
                  <p>
                    We will let you know via email and/or a prominent notice on
                    our Service, prior to the change becoming effective and
                    update “effective date” at the top of this Privacy Policy.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You are advised to review this Privacy Policy periodically
                    for any changes. Changes to this Privacy Policy are
                    effective when they are posted on this page.
                  </p>
                </div>

                <div className="text-2xl pt-2">21. Contact Us</div>
                <p>
                  If you have any questions about this Privacy Policy, please
                  contact us:
                </p>
                <div className="pt-3">
                  <p>
                    By visiting this page on our website:
                    https://sidehustletool.com/contact.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-8 inline-flex rounded-md shadow">
              <a
                href="https://sidehustletool.com/contact/"
                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
